// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useAppApi() {
  const toast = useToast()
  const searchApp = ref('')
  const searchMyApp = ref('')
  const searchVersion = ref('')
  const isLoading = ref(false)
  const appData = ref(null)
  const timer = ref(null)
  const TOP_DEFAULT_CONFIG = {
    pullText: 'Pull down to refresh',
    triggerText: 'Data is loading.',
    loadingText: 'Loading...',
    doneText: '',
    failText: 'Failed to load data',
    loadedStayTime: 400,
    stayDistance: 40,
    triggerDistance: 60
  }
  const deviceRead = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      return 'android'
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios'
    }
  }
  // ------------------------------------------------
  // Get App List
  // ------------------------------------------------
  const searchFetchApps = () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(() => {
      store.dispatch('appStore/resetApps')
      store.dispatch('appStore/searchApps', {
        perPage: 25,
        q: searchApp.value,
        os: deviceRead(),
      })
    }, 400)
  }

  // ------------------------------------------------
  // Get My App List
  // ------------------------------------------------
  const fetchMyApps = (ctx, callback) => {
    clearTimeout(timer.value)
    timer.value = setTimeout(() => {
      store.dispatch('appStore/resetMyApps')
      store.dispatch('appStore/searchMyApps', {
        perPage: 25,
        q: searchMyApp.value,
        os: deviceRead(),
      })
    }, 400)
  }

  // ------------------------------------------------
  // Get App Detail
  // ------------------------------------------------
  const fetchApp = async (ctx, callback) => {
    isLoading.value = true
    try{
      const response = await store.dispatch('appStore/fetchApp', { id: router.currentRoute.params.app })
      appData.value = response.data.data
    }
    catch(error){
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'Oops! Something went wrong!',
        },
      })
      router.push('/')
    }
    isLoading.value = false
  }

  const fetchVersions = (ctx, callback) => {
    clearTimeout(timer.value)
    timer.value = setTimeout(() => {
      store.dispatch('appStore/resetVersions')
      store.dispatch('appStore/searchVersions', {
        id: router.currentRoute.params.app,
        perPage: 25,
        q: searchVersion.value,
        os: deviceRead(),
      })
    }, 400)
  }


  return {
    TOP_DEFAULT_CONFIG,
    searchApp,
    searchFetchApps,
    fetchApp,
    isLoading,
    appData,
    searchVersion,
    fetchVersions,
    fetchMyApps,
    searchMyApp,
  }
}
